import React, { useState, useContext, useEffect, useRef } from "react"
import styled from "@emotion/styled"
import { CardContainer } from "../CardContainer"
import CardSwitch from "../CardSwitchHeader/CardSwitch"
import { QuoteContext } from "../../../../../Context/QuoteContext"
import { extractData } from "../../../../../utils/HelperFunctions"
import { calculateQuote, toggleAdditions } from "../../../../../Context/Actions"

const AdditionalCoverCard = () => {
  const { state, dispatch } = useContext(QuoteContext)
  const [checked, setChecked] = useState(state.additions.youngDriver.selected)
  const [prevChecked, setPrevChecked] = useState(
    state.additions.youngDriver.selected
  )

  const cover = extractData(
    state.quote.result.settings.CoverPack,
    "additions",
    "coverLevel"
  )
  // .coverDetail.filter(item => item.optional)

  const additions = extractData(
    state.quote.result.settings.CoverPack,
    state.coverLevel,
    "coverLevel"
  )

  const isMounted = useRef(true)

  useEffect(() => {
    if (isMounted.current) {
      isMounted.current = false
      return
    }

    if (checked !== prevChecked) dispatch(calculateQuote("youngDriver"))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.additions.youngDriver.selected])

  useEffect(() => {
    if (state.calcErrors.youngDriver.length > 0) {
      setChecked(prevChecked)
      dispatch(toggleAdditions("youngDriver"))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.calcErrors.youngDriver])

  const handleOnChange = id => {
    if (id === "youngDriver") {
      setPrevChecked(checked)
      setChecked(!state.additions.youngDriver.selected)
    }

    dispatch(toggleAdditions(id))
  }

  return (
    <CardContainer>
      <CardBody>
        <Heading>{cover.name}</Heading>
        <Text>{cover.description}</Text>
        {cover.coverDetail.map(item => {
          let headerText =
            item.id === 2001 ? "" : " (" + item.mValue + " kr/mån)"

          if (!additions.additions.includes(item.id)) {
            return null
          }

          if (
            (item.id === 3011 || item.id === 3012) &&
            state.quote.result.objectAge > 20
          )
            return null

          return (
            <AdditionalCover key={item.paramName}>
              <CardSwitch
                HeaderText={item.name + headerText}
                Style="AdditionalCard"
                initChecked={false}
                type="null"
                id={item.paramName}
                handleOnChange={e => handleOnChange(item.paramName)}
              ></CardSwitch>
              <AdditionalCoverText>{item.description}</AdditionalCoverText>
            </AdditionalCover>
          )
        })}
      </CardBody>
    </CardContainer>
  )
}
export default AdditionalCoverCard

const AdditionalCoverText = styled.p`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  color: #70669b;
  margin: 0;
  max-width: 250px;

  @media (min-width: 900px) {
    max-width: 520px;
  }
`

const AdditionalCover = styled.div`
  margin: 0;
  width: 100%;
  padding: 0 7px;

  &:not(:last-child) {
    margin-bottom: 23px;
  }
`

const Heading = styled.strong`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #5558ea;
  margin-bottom: 8px;
`

const Text = styled.strong`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
  color: #63638f;
  margin-bottom: 37px;
  text-align: center;
  max-width: 280px;
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  align-items: center;
`
