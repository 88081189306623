import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { CardContainer } from "../CardContainer"
import { QuoteContext } from "../../../../../Context/QuoteContext"
import { togglePaymentInterval } from "../../../../../Context/Actions"

const PaymentIntervalCard = () => {
  const { state, dispatch } = useContext(QuoteContext)
  const [checked, setChecked] = useState(state.quote.result.paymentMethod)

  const onChangeHandler = e => {
    setChecked(e.currentTarget.value)
    dispatch(togglePaymentInterval(e.currentTarget.value))
  }

  return (
    <CardContainer>
      <CardBody>
        <PaymentIntervalHeading>
          Hur ofta vill du betala?
        </PaymentIntervalHeading>
        <PaymentIntervalText>
          Välj om du vill betala för din försäkring varje månad eller en gång
          per år.
        </PaymentIntervalText>

        <RadioButtons>
          <RadioButton key="Monthly">
            <input
              type="checkbox"
              name="payment-group"
              id="Monthly"
              value="Monthly"
              onChange={onChangeHandler}
              checked={checked === "Monthly" ? true : false}
            />
            <label htmlFor="Monthly">Månad</label>
          </RadioButton>
          <RadioButton key="Annually">
            <input
              type="checkbox"
              name="payment-group"
              id="Annually"
              value="Annually"
              onChange={onChangeHandler}
              checked={checked === "Annually" ? true : false}
            />
            <label htmlFor="Annually">Helår</label>
          </RadioButton>
        </RadioButtons>

        <PaymentIntervalHint>
          Det tillkommer ingen extra avgift för månadsbetalning.
        </PaymentIntervalHint>
      </CardBody>
    </CardContainer>
  )
}
export default PaymentIntervalCard

const RadioButton = styled.div`
  display: flex;

  input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }

  input:checked + label {
    background-color: #5558ea;
    border-color: #5558ea;
    color: white;
    box-shadow: none;
  }

  label {
    color: #01cc86;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #01cc86;
    min-width: 74px;

    width: 100%;
    padding: 16px 10px;
    margin: 0;
    border-radius: 3px;
    border: 1px solid #01cc86;
    transition: all 0.2s ease-in-out;

    :hover {
      /* background: #5558e836; */
      cursor: pointer;
      color: #5558ea;
      border-color: #5558ea;
    }
  }
`

const RadioButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    margin-right: 7px;
    :last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: 374px) {
    flex-direction: column;
    width: 100%;

    div:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`

const PaymentIntervalHeading = styled.strong`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #5558ea;
  margin-bottom: 5px;
`

const PaymentIntervalHint = styled.strong`
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: center;
  color: #63638f;
  margin-top: 13px;
`

const PaymentIntervalText = styled.strong`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: center;
  color: #63638f;
  margin-bottom: 13px;

  /* @media (min-width: 800px) {
    max-width: 350px;
  } */
  @media (max-width: 414px) {
    max-width: 220px;
  }
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #ff4984;
    margin: 0;
    margin-top: 15px;
  }

  p {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    text-align: center;
    color: #70669b;
    margin: 0;
    margin-top: 5px;
  }

  label {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5558ea;
  }

  i {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    text-align: center;
    color: #70669b;
    text-decoration: none;
    margin-top: 5px;
  }
`
