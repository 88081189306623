import React, { useState, useContext, useRef, useEffect } from "react"
import styled from "@emotion/styled"
import { useTheme } from "emotion-theming"
import Icon from "../../../../Icon/Icon"
import { QuoteContext } from "../../../../../Context/QuoteContext"
import { updateCoverList, calculateQuote } from "../../../../../Context/Actions"
import { extractData } from "../../../../../utils/HelperFunctions"

const ComprehensiveCard = () => {
  const theme = useTheme()
  const { state, dispatch } = useContext(QuoteContext)
  const [checked, setChecked] = useState(state.coverList.excessLevel)
  const [prevChecked, setPrevChecked] = useState(state.coverList.excessLevel)
  const isMounted = useRef(true)

  useEffect(() => {
    if (isMounted.current) {
      isMounted.current = false
      return
    }

    if (checked !== prevChecked) dispatch(calculateQuote("excessLevel"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.coverList.excessLevel])

  useEffect(() => {
    if (state.calcErrors.excessLevel.length > 0) {
      setChecked(prevChecked)
      dispatch(
        updateCoverList({
          name: "excessLevel",
          value: prevChecked,
        })
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.calcErrors.excessLevel])

  const cover = extractData(
    state.quote.result.settings.CoverPack,
    "Hel",
    "coverLevel"
  )

  const argumentInfo = extractData(
    state.quote.result.settings.ArgumentInfo,
    "excessLevel",
    "name"
  )

  const coverDetail = extractData(
    cover.coverDetail,
    "excessLevel",
    "deductible"
  )

  const options = Object.entries(
    argumentInfo.idValueMap
  ).map(([key, value]) => ({ key, value }))

  const content = {
    coverLevel: cover.name,
    header: coverDetail.name,
    description: coverDetail.description,
    options: options,
  }

  const onChangeHandler = e => {
    setPrevChecked(checked)
    setChecked(e.currentTarget.value)
    dispatch(
      updateCoverList({
        name: "excessLevel",
        value: e.currentTarget.value,
      })
    )
  }

  return (
    <ContentContainer>
      <CardBody>
        <Icon
          name="self-accident"
          bgColor={theme.colors.CardContainer.iconBackground}
          iconColor={theme.colors.CardContainer.iconColor}
          iconSize="25"
        />

        <h3>{content.header}</h3>
        <p>{content.description}</p>

        <span>Välj självkostnadspris (Självrisk):</span>
        <i>Högre självrisk ger en lägre månadskostnad.</i>

        <RadioButtons>
          {content.options.map(item => {
            if (item.key === "6000") return null
            return (
              <RadioButton key={item.key}>
                <input
                  type="checkbox"
                  name="excess-group"
                  id={item.key}
                  value={item.key}
                  onChange={onChangeHandler}
                  checked={checked === item.key ? true : false}
                />
                <label htmlFor={item.key}>{item.value}</label>
              </RadioButton>
            )
          })}
        </RadioButtons>
      </CardBody>
    </ContentContainer>
  )
}
export default ComprehensiveCard

export const ContentContainer = styled.div`
  padding: 30px 20px;

  @media (max-width: 375px) {
    padding: 30px 14px;
  }
`

const RadioButton = styled.div`
  display: flex;
  width: 74px;

  input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }

  input:checked + label {
    background-color: #5558ea;
    border-color: #5558ea;
    color: white;
    box-shadow: none;
  }

  label {
    color: #01cc86;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #01cc86;

    width: 100%;
    padding: 16px 0;

    border-radius: 3px;
    border: 1px solid #01cc86;
    transition: all 0.2s ease-in-out;

    :hover {
      cursor: pointer;
      color: #5558ea;
      border-color: #5558ea;
    }
  }
`

const RadioButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 100%;
  max-width: 280px; */
  /* width: 74px; */

  div {
    margin-right: 7px;
  }

  div:last-of-type {
    margin-right: 0px;
  }

  @media (max-width: 320px) {
    div {
      margin-right: 4px;
    }

    div:last-of-type {
      margin-right: 0px;
    }
  }
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #ff4984;
    margin: 0;
    margin-top: 15px;
  }

  p {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    text-align: center;
    color: #70669b;
    margin: 0;

    @media (min-width: 375px) {
      max-width: 282px;
    }

    @media (min-width: 600px) {
      max-width: 440px;
    }
  }

  span {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5558ea;
    margin-top: 24px;
  }

  i {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    text-align: center;
    color: #70669b;
    text-decoration: none;
    margin-top: 5px;
    margin-bottom: 8px;
  }
`
