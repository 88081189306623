import React, { useContext } from "react"
import styled from "@emotion/styled"
import { useTheme } from "emotion-theming"
import { Section } from "../../../Section"
import { QuoteContext } from "../../../../../Context/QuoteContext"
import { extractData } from "../../../../../utils/HelperFunctions"
import Switch from "react-switch"
import { toggleCoverLevel } from "../../../../../Context/Actions"

const QuoteSubHero = () => {
  const theme = useTheme()
  const { state, dispatch } = useContext(QuoteContext)

  const cover = extractData(
    state.quote.result.settings.CoverPack,
    "Trafik",
    "coverLevel"
  )

  const coverDetail = extractData(cover.coverDetail, 100, "id")

  const handleOnChange = e => {
    dispatch(
      toggleCoverLevel(
        state.coverLevel === "Trafik" ? state.prevCoverLevel : "Trafik"
      )
    )
  }

  return (
    <Section bgColor={theme.colors.quoteSubHero.background}>
      <SubHero>
        <h2>{cover.description}</h2>
        <p>{coverDetail.description}</p>

        <SwitchContainer>
          <div>
            <span>Jag vill endast ha trafikförsäkring</span>
            <Switch
              disabled={false}
              onChange={handleOnChange}
              checked={state.coverLevel === "Trafik"}
              onColor="#5558ea"
              offColor="#c6c6c6"
              uncheckedIcon={false}
              checkedIcon={false}
              handleDiameter={15}
              width={43}
              height={23}
              activeBoxShadow="0px 0px 1px 8px #ff4984"
            />
          </div>
        </SwitchContainer>
      </SubHero>
    </Section>
  )
}
export default QuoteSubHero

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  span {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    text-align: center;
    color: #63638f;
    margin-bottom: 0;
    margin-right: 10px;
  }
`

const SubHero = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 395px;

  h2 {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 12px;
    color: ${({ theme }) => theme.colors.quoteSubHero.headingColor};

    @media (max-width: 375px) {
      max-width: 295px;
    }
  }

  p {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: #63638f;
    margin-bottom: 0;

    @media (max-width: 414px) {
      max-width: 300px;
    }

    @media (max-width: 375px) {
      max-width: 295px;
    }
  }

  strong {
    font-size: 12px;
    color: #6569a6;

    span {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      -webkit-letter-spacing: normal;
      -moz-letter-spacing: normal;
      -ms-letter-spacing: normal;
      letter-spacing: normal;
      color: #70669b;
      margin: 0;
    }
  }
`
