import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { QuoteContext } from "../../../../../Context/QuoteContext"
import { updateCoverList, calculateQuote } from "../../../../../Context/Actions"
import { extractData } from "../../../../../utils/HelperFunctions"
import Switch from "react-switch"

const DeductableConceptCard = () => {
  const { state, dispatch } = useContext(QuoteContext)
  const [checked, setChecked] = useState(state.coverList.deductableConcept)
  const [prevChecked, setPrevChecked] = useState(
    state.coverList.deductableConcept
  )

  const argumentInfo = extractData(
    state.quote.result.settings.ArgumentInfo,
    "deductableConcept",
    "name"
  )

  const options = Object.entries(
    argumentInfo.idValueMap
  ).map(([key, value]) => ({ key, value }))

  const onChangeHandler = e => {
    if (state.isLoading) return

    setPrevChecked(checked)
    setChecked(e ? "Normal" : "High")

    dispatch(
      updateCoverList({
        name: "deductableConcept",
        value: e ? "Normal" : "High",
      })
    )
  }

  const isMounted = useRef(true)

  useEffect(() => {
    if (isMounted.current) {
      isMounted.current = false
      return
    }

    if (checked !== prevChecked) dispatch(calculateQuote("deductableConcept"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.coverList.deductableConcept])

  useEffect(() => {
    if (state.calcErrors.deductableConcept.length > 0) {
      setChecked(prevChecked)
      dispatch(
        updateCoverList({
          name: "deductableConcept",
          value: prevChecked,
        })
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.calcErrors.deductableConcept])

  return (
    <DeductableConceptContainer>
      <SwitchContainer>
        <div>
          <span>Premium-försäkring</span>
          <Switch
            disabled={false}
            onChange={onChangeHandler}
            checked={checked === "Normal" ? true : false}
            onColor="#5558ea"
            offColor="#c6c6c6"
            uncheckedIcon={false}
            checkedIcon={false}
            handleDiameter={15}
            width={43}
            height={23}
            activeBoxShadow="0px 0px 1px 8px #ff4984"
          />
        </div>
      </SwitchContainer>
      <DeductableConceptInfoText>
        Med premium påslaget sänker vi flera av självriskerna så du får ut mer
        av din försäkring om olyckan skulle vara framme.
      </DeductableConceptInfoText>
    </DeductableConceptContainer>
  )
}

export default DeductableConceptCard

const DeductableConceptInfoText = styled.div`
  margin-top: 15px;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #63638f;
  margin-bottom: 0;

  max-width: 300px;

  @media (max-width: 375px) {
    max-width: 295px;
  }
`

const DeductableConceptContainer = styled.div`
  margin-bottom: 40px;

  span {
    font-size: 15px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: #63638f;
    margin-bottom: 0;
  }
`

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  span {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    text-align: center;
    color: #63638f;
    margin-bottom: 0;
    margin-right: 10px;
  }
`
