import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { CardContainer } from "../CardContainer"
import { QuoteContext } from "../../../../../Context/QuoteContext"
import { updateCoverList, calculateQuote } from "../../../../../Context/Actions"
import { extractData } from "../../../../../utils/HelperFunctions"

const MileageCard = () => {
  const { state, dispatch } = useContext(QuoteContext)
  const [checked, setChecked] = useState(state.coverList.mileage)
  const [prevChecked, setPrevChecked] = useState(state.coverList.mileage)

  const argumentInfo = extractData(
    state.quote.result.settings.ArgumentInfo,
    "mileage",
    "name"
  )

  const options = Object.entries(
    argumentInfo.idValueMap
  ).map(([key, value]) => ({ key, value }))

  const onChangeHandler = e => {
    if (state.isLoading) return

    setPrevChecked(checked)
    setChecked(e.currentTarget.value)

    dispatch(
      updateCoverList({
        name: "mileage",
        value: e.currentTarget.value,
      })
    )
  }

  const isMounted = useRef(true)

  useEffect(() => {
    if (isMounted.current) {
      isMounted.current = false
      return
    }

    if (checked !== prevChecked) dispatch(calculateQuote("mileage"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.coverList.mileage])

  useEffect(() => {
    if (state.calcErrors.mileage.length > 0) {
      setChecked(prevChecked)
      dispatch(
        updateCoverList({
          name: "mileage",
          value: prevChecked,
        })
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.calcErrors.mileage])

  return (
    <CardContainer>
      <CardBody>
        <MileageHeading>Hur långt kör du varje år?</MileageHeading>
        <MileageText>
          Om du inte vet exakt så kan du välja något nu och sedan i efterhand
          ändra på körsträckan.
        </MileageText>

        <RadioButtons>
          {options.map(item => {
            return (
              <RadioButton key={item.key}>
                <input
                  type="checkbox"
                  name="mileage-group"
                  id={item.key}
                  value={item.key}
                  onChange={onChangeHandler}
                  checked={checked === item.key ? true : false}
                />
                <label htmlFor={item.key}>{item.value}</label>
              </RadioButton>
            )
          })}
        </RadioButtons>
      </CardBody>
    </CardContainer>
  )
}
export default MileageCard

const RadioButton = styled.div`
  display: flex;

  input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }

  input:checked + label {
    background-color: #5558ea;
    border-color: #5558ea;
    color: white;
    box-shadow: none;
  }

  label {
    color: #01cc86;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #01cc86;

    width: 100%;
    padding: 16px 10px;
    margin: 0;
    border-radius: 3px;
    border: 1px solid #01cc86;
    transition: all 0.2s ease-in-out;

    :hover {
      /* background: #5558e836; */
      cursor: pointer;
      color: #5558ea;
      border-color: #5558ea;
    }
  }
`

const RadioButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    margin-right: 7px;
    :last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: 374px) {
    flex-direction: column;
    width: 100%;

    div:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`

const MileageHeading = styled.strong`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #5558ea;
  margin-bottom: 5px;
`

const MileageText = styled.strong`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: center;
  color: #63638f;
  margin-bottom: 13px;

  @media (min-width: 800px) {
    max-width: 350px;
  }
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #ff4984;
    margin: 0;
    margin-top: 15px;
  }

  p {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    text-align: center;
    color: #70669b;
    margin: 0;
    margin-top: 5px;
  }

  label {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5558ea;
  }

  i {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    text-align: center;
    color: #70669b;
    text-decoration: none;
    margin-top: 5px;
  }
`
