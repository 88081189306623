import React, { useContext } from "react"
import styled from "@emotion/styled"
import Switch from "react-switch"
import { QuoteContext } from "../../../../../Context/QuoteContext"

const CardSwitch = ({ HeaderText, id, handleOnChange }) => {
  const { state } = useContext(QuoteContext)

  return (
    <HeaderRow DefaultHeading={false} isSelected={state.additions[id].selected}>
      <AdditionalHeading>{HeaderText}</AdditionalHeading>

      <label>
        <span>{state.additions[id].selected ? "Ja" : "Nej"}</span>
        <Switch
          onChange={handleOnChange}
          checked={state.additions[id].selected}
          onColor="#5558ea"
          uncheckedIcon={false}
          checkedIcon={false}
          handleDiameter={15}
          width={43}
          height={23}
          activeBoxShadow="0px 0px 1px 8px #ff4984"
        />
      </label>
    </HeaderRow>
  )
}
export default CardSwitch

const AdditionalHeading = styled.strong`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #5558ea;
  margin: 0;
`

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${props => (props.DefaultHeading ? "#5558ea" : "#5558ea")};
  font-size: ${props => (props.DefaultHeading ? "16px" : "14px")};
  margin-bottom: ${props => (props.DefaultHeading ? "30px" : "5px")};

  @media (max-width: 374px) {
    margin-bottom: 15px;
  }

  label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  span {
    font-weight: 600;
    margin-right: 10px;
    color: ${props => (props.isSelected ? "#5558ea" : "#a3a3a3")};
  }
`
