import styled from "@emotion/styled"

export const CardContainer = styled.div`
  object-fit: contain;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.colors.CardContainer.boxShadow};
  background-color: ${({ theme }) => theme.colors.CardContainer.background};
  padding: 30px 20px;
  margin-bottom: 30px;
  width: 100%;
  max-width: 600px;

  @media (min-width: 600px) {
    min-width: 600px;
  }

  @media (max-width: 375px) {
    padding: 30px 14px;
  }
`
