import React, { useContext, useEffect, useCallback } from "react"
import styled from "@emotion/styled"
import { QuoteContext } from "../../Context/QuoteContext"
import { navigate } from "gatsby"
import { useTheme } from "emotion-theming"
import Layout from "../../components/Layout"
import { Section } from "../../components/PageComponents/Section"
import QuoteSubHero from "../../components/PageComponents/QuotePage/QuoteSubHero/QuoteSubHero.js/QuoteSubHero"
import ComprehensiveCard from "../../components/PageComponents/QuotePage/cards/comprehensive"
import PartialCoverCard from "../../components/PageComponents/QuotePage/cards/PartialCover/PartialCover"
import MileageCard from "../../components/PageComponents/QuotePage/cards/Mileage/Mileage"
import AdditionalCoverCard from "../../components/PageComponents/QuotePage/cards/AdditionalCover/AdditionalCover"
import SEO from "../../components/seo"
import {
  initFetchQuote,
  toggleCoverLevel,
  formResetError,
} from "../../Context/Actions"
import Icon from "../../components/Icon/Icon"
import Loader from "../../components/GlobalAssets/Loader"
import PaymentIntervalCard from "../../components/PageComponents/QuotePage/cards/PaymentInterval/PaymentInterval"
import DeductableConceptCard from "../../components/PageComponents/QuotePage/cards/deductableConcept/deductableConcept"

const OffertPage = props => {
  const { state, dispatch } = useContext(QuoteContext)
  const theme = useTheme()
  const stableDispatch = useCallback(dispatch, [])

  useEffect(() => {
    return () => {
      // Reset error-messages when unmounting
      dispatch(formResetError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    stableDispatch(initFetchQuote(false))
  }, [stableDispatch])

  if (!state.hasQuote) {
    if (typeof window === "undefined") {
      return null
    }

    navigate("/")
    return <Loader />
  }

  const onSubmitHandler = e => {
    e.preventDefault()

    navigate(`/bilforsakring/valj-startdag`, { replace: true })
  }

  const handeOnClick = e => {
    dispatch(toggleCoverLevel(e.target.id))
  }

  return (
    <Layout location={props.location}>
      <SEO title="evoli - Se pris och teckna försäkring" noIndex={true} />
      {state.isLoading && <Loader />}
      <form
        onSubmit={onSubmitHandler}
        disabled={state.isLoading}
        style={{ margin: 0 }}
      >
        <Section bgColor="#ffffff">
          <QuoteHeroContent>
            <Icon
              name="calculator"
              bgColor={theme.colors.CardContainer.iconBackground}
              iconColor={theme.colors.CardContainer.iconColor}
              iconSize="25"
            />
            <h1>Ditt pris är beräknat</h1>
            <h4>
              {state.quote.result.objectName} {state.quote.result.objectName2}
            </h4>
            <p>
              Välj hel, halv eller trafikförsäkring samt tillval och körsträcka
              för att se hur priset ändras.
            </p>
          </QuoteHeroContent>
        </Section>
        <QuoteSubHero />

        <Section bgColor={"#fff"}>
          <DeductableConceptCard />

          {state.coverLevel !== "Trafik" && (
            <TabContainer>
              <Tabs>
                <Tab
                  id="Hel"
                  type="button"
                  isActive={state.coverLevel === "Hel"}
                  onClick={handeOnClick}
                >
                  {state.coverLevel === "Hel" && <CheckMarker />}
                  Helförsäkring
                </Tab>
                <Tab
                  id="Halv"
                  isActive={state.coverLevel === "Halv"}
                  onClick={handeOnClick}
                  type="button"
                >
                  {state.coverLevel === "Halv" && <CheckMarker />}
                  Halvförsäkring
                </Tab>
              </Tabs>

              <TabContent
                borderRight={state.coverLevel === "Hel" ? true : false}
              >
                {state.coverLevel === "Hel" && <ComprehensiveCard />}

                <PartialCoverCard />
              </TabContent>
            </TabContainer>
          )}

          <MileageCard />

          {state.quote.result.paymentMethod && <PaymentIntervalCard />}

          <AdditionalCoverCard />
        </Section>
        <InformationContainer>
          <Information>
            <strong>Trygghet i världsklass!</strong>
            <p>
              Vi är licensierade av Finansinspektionen och bakom oss har vi ett
              par av världens största globala försäkringsbolag, vilket
              garanterar alla utbetalningar till dig som kund.
            </p>
          </Information>
          <Information>
            <strong>100% ångerrätt, hela tiden!</strong>
            <p>
              Hos oss kan du alltid säga upp din försäkring precis när du vill
              och din försäkring avslutas direkt. Du kan med andra ord byta till
              ett annat försäkringsbolag om du hittar ett billigare, eller om du
              inte är nöjd.
            </p>
          </Information>
          <Information>
            <strong>Vad betyder självrisk?</strong>
            <p>
              Självrisk är en försäkringsterm som betyder den andel av kostnaden
              som du står för vid en skada. Genom att ha en självrisk så kan
              försäkringsbolag erbjuda en lägre månadskostnad till alla sina
              kunder då kunderna på egen hand kan stå för mindre
              engångskostnader.
            </p>
          </Information>
        </InformationContainer>
        <QuoteFooter state={state} isLoading={state.isLoading} />
      </form>
    </Layout>
  )
}
export default OffertPage

function calculatePremium(state) {
  if (state.paymentInterval === "Annually") {
    let price = state.quote.result["Annual" + state.coverLevel + "Only"]

    if (state.additions["optAnimalCollision"]?.selected === true) {
      price = price + state.quote.result.optional["3010"].annualPremiumWithTax
    }

    if (state.additions["optRoadSide"]?.selected === true) {
      price = price + state.quote.result.optional["3011"].annualPremiumWithTax
    }

    if (state.additions["optHiredCar"]?.selected === true) {
      price = price + state.quote.result.optional["3012"].annualPremiumWithTax
    }

    return price
  }

  let price = state.quote.result[state.coverLevel + "Only"]

  if (state.additions["optAnimalCollision"]?.selected === true) {
    price = price + state.quote.result.optional["3010"].monthlyPremiumWithTax
  }

  if (state.additions["optRoadSide"]?.selected === true) {
    price = price + state.quote.result.optional["3011"].monthlyPremiumWithTax
  }

  if (state.additions["optHiredCar"]?.selected === true) {
    price = price + state.quote.result.optional["3012"].monthlyPremiumWithTax
  }

  return price
}

const QuoteFooter = props => {
  return (
    <>
      <ErrorWrapper
        show={
          props.state.calcErrors.youngDriver.length > 0 ||
          props.state.calcErrors.mileage.length > 0 ||
          props.state.calcErrors.deductableConcept.length > 0 ||
          props.state.calcErrors.excessLevel.length > 0
        }
      >
        <ErrorMessage>
          Något gick fel och vi kan tyvärr inte uppdatera priset.
        </ErrorMessage>
      </ErrorWrapper>

      <StickyFooter>
        <FooterRow>
          <FooterLeftContainer>
            <FooterHeader>
              Pris för {props.state.coverLevel}försäkring
            </FooterHeader>
            <Price>
              {calculatePremium(props.state)}

              <span>
                kr/{props.state.paymentInterval === "Monthly" ? "mån" : "år"}
              </span>
            </Price>
          </FooterLeftContainer>
          <FooterRightContainer>
            <button disabled={props.state.isLoading} type="submit">
              Köp
            </button>
          </FooterRightContainer>
        </FooterRow>
        <FooterParagraph>
          Genom att gå vidare så bekräftar du att du läst igenom och godkänner{" "}
          <a
            href="/forkopsinformation/forkopsinformation_bilforsakring_2020-06-01.pdf"
            target="_blank"
          >
            förköpsinformationen
          </a>
          ,{" "}
          <a
            href="/forsakringsvillkor/Forsakringsvillkor_bil_2020-06-01.pdf"
            target="_blank"
          >
            försäkringsvillkoren
          </a>{" "}
          och{" "}
          <a href="/produktfaktablad/senaste.pdf" target="_blank">
            produktfaktabladet
          </a>{" "}
          samt att få dina försäkringshandlingar digitalt.
        </FooterParagraph>
      </StickyFooter>
    </>
  )
}

const CheckMarker = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="14"
      viewBox="0 0 17 14"
    >
      <path
        fill="#5558EA"
        fillRule="nonzero"
        d="M5.926 13.414L.293 7.424 2.442 5.14l3.484 3.705L13.97.293l2.149 2.285z"
      />
    </svg>
  )
}

const QuoteHeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  h1,
  p {
    max-width: 305px;
  }

  h1 {
    margin: 0;
    margin-top: 10px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #ff4984;

    span {
      display: block;
      color: #5558ea;
      margin-top: 10px;
    }
  }

  p,
  h4 {
    margin-top: 6px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #63638f;
    margin-bottom: 0;
  }

  p {
    margin-top: 4px;
  }

  h4 {
    font-weight: 700;
  }
`

const TabContainer = styled.div`
  width: 100%;
  box-shadow: 0 0 22px 2px rgba(170, 169, 173, 0.2);
  border-radius: 8px;
  margin-bottom: 30px;
  max-width: 600px;
`

const Tabs = styled.div`
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: row;
`

const Tab = styled.button`
  background-color: inherit;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  letter-spacing: -0.51px;
  border-radius: 10px 10px 0 0;
  background: white;
  text-align: center;
  font-weight: bold;
  font-size: 16px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 22px 2px rgba(170, 169, 173, 0.2);

  background: ${props => (props.isActive ? "white" : "#c4ffe1")};
  color: ${props => (props.isActive ? "#5558ea" : "#01a17d")};

  :first-of-type {
    margin-right: 5px;
  }

  svg {
    margin-right: 5px;
  }

  :hover {
    cursor: ${props => (props.isActive ? "default" : "pointer")};
  }
`
const TabContent = styled.div`
  padding: 6px 12px;
  background: white;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-top: -5px;
  position: relative;

  ${props =>
    props.borderRight
      ? " border-top-right-radius: 8px;"
      : " border-top-left-radius: 8px;"}
`

const InformationContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 415px;
  margin-bottom: 30px;
  padding: 0 20px;
  text-align: center;

  @media (max-width: 414px) {
    max-width: 300px;
    padding: 0;
  }

  @media (max-width: 374px) {
    padding: 0 10px;
    text-align: left;
  }

  strong {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    color: #5558ea;
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;

    color: #63638f;
  }
`

const FooterParagraph = styled.p`
  color: #1e1255;
  font-size: 10px;
  font-weight: 600;
  padding: 5px 20px 10px 20px;
  margin: 0;
  max-width: 600px;

  a {
    color: #1e1255;
    text-decoration: underline;
  }
  @media (max-width: 374px) {
    font-size: 8px;
    a {
      font-size: 8px;
    }
  }
`

const FooterRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Price = styled.div`
  font-size: 42px;
  line-height: 1;
  letter-spacing: 0;
  font-weight: 900;

  span {
    font-size: 21px;
    margin-left: 5px;
    font-weight: normal;
  }

  svg {
    width: 30px;
    margin: 0;

    animation: rotate 1.5s linear infinite;

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @media (max-width: 374px) {
      width: 15px;
      height: 15px;
    }

    @media (max-width: 375px) {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 374px) {
    font-size: 30px;
  }
`

const FooterHeader = styled.div`
  font-size: 13px;
  font-weight: 600;
  text-transform: lowercase;
  :first-letter {
    text-transform: uppercase;
  }

  @media (max-width: 374px) {
    font-size: 12px;
  }
`

const FooterLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  padding: 10px 20px 0 20px;

  button {
    border: 1.8px solid white;
    background: none;
    border-radius: 8px;
    color: white;
    font-weight: bold;
    padding: 10px 20px;
    font-size: 16px;

    :hover {
      cursor: pointer;
    }

    :disabled {
      cursor: wait;
    }

    @media (max-width: 374px) {
      font-size: 14px;
      padding: 8px 15px;
    }
  }
`
const ErrorWrapper = styled.div`
  width: 100%;
  position: fixed;
  font-size: 12px;
  color: #090919;
  bottom: 122px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${props => (props.show ? "translateY(0%)" : "translateY(130%)")};
  transition: transform 0.3s ease-in-out;

  @media (max-width: 374px) {
    bottom: 100px;
    transform: ${props => (props.show ? "translateY(0%)" : "translateY(175%)")};
    font-size: 11px;
  }
`

const ErrorMessage = styled.div`
  box-shadow: 0 0 22px 2px rgba(170, 169, 173, 0.2);
  background-color: #ffd2e1;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 8px;
  text-align: center;

  @media (max-width: 374px) {
    padding: 10px 15px;
  }
`

const StickyFooter = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  background: #5558ea;
  color: white;
  z-index: 50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    font-size: 10px;
    margin: 0;
    color: #1f463d;
    line-height: 1.1;
  }
`
