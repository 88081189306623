import React from "react"
import styled from "@emotion/styled"
import { useTheme } from "emotion-theming"
import Icon from "../../../../Icon/Icon"

const CoverList = props => {
  const theme = useTheme()

  if (props.optional) {
    return null
  }

  let value = props.deductible.replace(/\\n/g, "<br />")

  return (
    <CoverContainer>
      <CoverHead>
        <Icon
          name={props.id}
          bgColor={theme.colors.CardContainer.secondaryIconBackground}
          iconColor={theme.colors.CardContainer.secondaryIconColor}
          size="30"
          iconSize="18"
        />
        <CoverHeading>{props.name}</CoverHeading>
      </CoverHead>
      <CoverText>{props.description}</CoverText>

      <ExcessInfo
        dangerouslySetInnerHTML={{
          __html: `<strong>Självrisk:</strong> ${value}`,
        }}
      ></ExcessInfo>
    </CoverContainer>
  )
}
export default CoverList

const ExcessInfo = styled.p`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #70669b;
  margin: 0;

  strong {
    font-size: 12px;
    color: #5558ea;
  }
`

const CoverHeading = styled.h4`
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff4984;
  margin: 0 0 15px 10px;
`

const CoverText = styled.p`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: normal;
  color: #63638f;
  margin: 0;
  margin-bottom: 10px;
`
const CoverHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

const CoverContainer = styled.div`
  margin: 0;

  display: flex;
  flex-direction: column;

  :not(:first-of-type) {
    margin-top: 30px;
  }
`
