import React, { useContext } from "react"
import styled from "@emotion/styled"
import CoverList from "./CoverList"
import { QuoteContext } from "../../../../../Context/QuoteContext"
import { extractData } from "../../../../../utils/HelperFunctions"

const PartialCoverCard = () => {
  const { state } = useContext(QuoteContext)

  const cover = extractData(
    state.quote.result.settings.CoverPack,
    "Halv",
    "coverLevel"
  )

  return (
    <ContentContainer>
      <CardBody>
        {cover.coverDetail.map(item => (
          <CoverList key={item.id} {...item} />
        ))}
      </CardBody>
    </ContentContainer>
  )
}
export default PartialCoverCard

export const ContentContainer = styled.div`
  padding: 30px 20px;

  @media (max-width: 375px) {
    padding: 30px 14px;
  }
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
